










































import { Component, Vue } from 'vue-property-decorator';

import { IWordstatTaskUpdate } from '@/interfaces/wordstat';
import {
  dispatchGetWordstatTasks,
  dispatchUpdateWordstatTask,
} from '@/store/wordstat/actions';
import { readOneWordstatTask } from '@/store/wordstat/getters';
import { nameRules } from './validators';

@Component
export default class WordstatTask extends Vue {
  public submitting = false;
  public valid = false;
  public name = '';
  public description = '';
  public domains: string[]|null = null;
  public nameRules = nameRules;

  public async mounted() {
    this.reset();  // чтобы сразу показать в полях значения, которые уже загружены
    await dispatchGetWordstatTasks(this.$store);
    this.reset();  // обновляем значения полей, если они изменились
  }

  public reset() {
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
    } else this.name = this.description = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const updatedTask: IWordstatTaskUpdate = {
        name: this.name,
        description: this.description || undefined,
      };
      await dispatchUpdateWordstatTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/wordstat');
    }
  }

  get task() {
    return readOneWordstatTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public get isNew() {
    return this.task?.status === 'NEW';
  }

}
